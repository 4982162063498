<template>
  <div>
    <base-toolbar>
      <template v-slot:icon>mdi-account-box-outline</template>
      <template v-slot:filters>
        <v-col cols="7" sm="4" md="2" lg="2" class="pl-0 pl-sm-3">
          <v-text-field
            hide-details
            label="Search"
            :value="filterKeyword"
            placeholder="Search"
            solo
            flat
            background-color="grey"
            dense
            single-line
            append-icon="mdi-magnify"
            class="shrink"
            @input="$emit('update:filter-keyword', $event)"
          />
        </v-col>
        <!--<v-col cols="3" md="2" lg="2" class="d-none d-sm-inline">
          <v-select
            label="User role"
            :value="filterRole"
            :items="userRoles"
            solo
            flat
            background-color="grey"
            dense
            single-line
            hide-details
            class="shrink"
            @input="$emit('update:filter-role', $event)"
          />
        </v-col>
        <v-col v-if="isSuperUser" cols="3" md="2" lg="2" class="d-none d-sm-inline">
          <v-select
            label="Client"
            :value="filterClient"
            :items="itemsClient"
            item-text="name"
            item-value="id"
            solo
            flat
            background-color="grey"
            dense
            single-line
            hide-details
            class="shrink"
            @input="$emit('update:filter-client', $event)"
          />
        </v-col>-->
        <div class="text-center">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-1"
                elevation="0"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-badge
                  :value="showBadge()"
                  color="primary"
                  overlap
                  dot
                >
                  <v-icon>mdi-filter-outline</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Filter</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider />

              <v-list>
                <v-list-item>
                  <v-select
                    label="User role"
                    :value="filterRole"
                    :items="userRoles"
                    solo
                    flat
                    background-color="grey"
                    style="max-width:237px"
                    dense
                    single-line
                    clearable
                    hide-details
                    class="shrink"
                    @input="$emit('update:filter-role', $event)"
                  />
                </v-list-item>

                <v-list-item v-if="isSuperUser">
                  <async-search-select
                    v-model="client"
                    label="Client"
                    :items="clientList"
                    item-text="name"
                    :items-update="fetchClientRefs"
                  />
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
        <v-tooltip
          v-if="showClearFilterButton"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey"
              class="mx-2"
              fab
              small
              :elevation="0"
              @click="clearFilter"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="error">mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Clear Filters</span>
        </v-tooltip>

        <v-spacer />

        <div class="ml-auto">
          <v-btn
            v-if="isSuperUser"
            color="primary lighten-2"
            class="mr-2 d-none d-md-inline"
            depressed
            outlined
            @click="showCreateDialog = true"
          >
            <v-icon class="mr-sm-1">mdi-shield-account-outline</v-icon>
            <span class="d-none d-md-block">Add User</span>
          </v-btn>
          <v-btn
            color="primary"
            class="mr-0 d-none d-md-inline"
            depressed
            @click="invite"
          >
            <v-icon class="pr-2">mdi-account-plus-outline</v-icon><span class="d-none d-md-block">Invite User</span>
          </v-btn>
          <v-menu
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="d-flex d-md-none"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="isSuperUser"
                link
                @click="showCreateDialog = true"
              >
                <v-list-item-icon>
                  <v-icon>mdi-shield-account-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Add User</v-list-item-title>
                  <v-list-item-subtitle class="red--text">Super User perm</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                @click="invite"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-account-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="primary--text text--lighten-2">Invite User</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </base-toolbar>
    <invite-user-dialog
      :show-invite-dialog.sync="showInviteDialog"
      v-on:reload-items="$emit('reload-items')"
    />

    <create-user-dialog
      :show-create-dialog.sync="showCreateDialog"
      v-on:reload-items="$emit('reload-items')"
    />
  </div>
</template>

<script>
import BaseToolbar from '@/components/toolbar/BaseToolbar'
import { userRoles } from '@/data/common'
import InviteUserDialog from '@/components/dialog/user/InviteUserDialog'
import CreateUserDialog from '@/components/dialog/user/CreateUserDialog.vue'
import UserService from '@/services/user.service'
import AsyncSearchSelect from '@/components/base/AsyncSearchSelect.vue'
import ClientService from '@/services/client.service'

export default {
  components: {
    AsyncSearchSelect,
    BaseToolbar,
    InviteUserDialog,
    CreateUserDialog
  },
  props: {
    filterKeyword: {},
    filterRole: {
      type: String,
      default: null
    },
    filterClient: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {
      clientList: [],
      userRoles,
      showInviteDialog: false,
      showCreateDialog: false,
      isSuperUser: false,
      menu: false
    }
  },
  computed: {
    showClearFilterButton() {
      return !!(this.filterKeyword || this.filterRole || this.filterClient)
    },
    client: {
      get() {
        return this.filterClient
      },
      set(value) {
        this.$emit('update:filter-client', value)
      }
    }
  },
  created() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
    }
  },
  methods: {
    async fetchClientRefs(search) {
      ClientService.getClientRefs(1, 10, search).then((resp) => {
        this.clientList = resp.results
      })
    },
    showBadge() {
      if (this.filterStatus || this.filterType || this.filterClient || this.filterAsset) {
        return true
      } else {
        return ''
      }
    },
    clearFilter() {
      this.$emit('update:filter-keyword', '')
      this.$emit('update:filter-role', null)
      this.$emit('update:filter-client', null)
    },
    invite() {
      this.showInviteDialog = true
    }
  }
}
</script>

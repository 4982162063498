
<template>
  <div>
    <base-dialog
      :show="showCreateDialog"
      form-title="Add User"
      icon="mdi-account-plus-outline"
      text-btn2="Save"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">FULL NAME <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-text-field
              v-model="fullName"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter full name"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">EMAIL <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              placeholder="Enter email"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">ROLE <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-select
              v-model="role"
              :items="allRoles"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Select a user role"
              outlined
              dense
              @change="updateClientId"
            />
          </v-col>
          <v-col v-if="role !== 'Superuser'" cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">CLIENT <span class="h6 primary--text text--lighten-2">*</span></div>
            <async-search-select
              v-model="client"
              placeholder="Select a client"
              :items="itemsClient"
              item-text="name"
              :items-update="fetchClientRefs"
              :rules="[v => !!v || 'This field is required']"
              base-style=""
              :style-extra="{ dense: true, flat: true, outlined: true, 'hide-details': false}"
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              PROVIDER <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-select
              v-model="provider"
              :rules="[v => !!v || 'This field is required']"
              :items="providers"
              placeholder="Select a provider"
              outlined
              dense
            />
          </v-col>
          <v-col v-if="provider === 'internal' || provider === null" cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              PHONE <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-text-field
              v-model="phone"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter phone number"
              outlined
              dense
            />
          </v-col>
          <v-col v-if="provider === 'internal' || provider === null" cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              PASSWORD <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              outlined
              placeholder="Enter a password of at least 10 characters"
              dense
            />
          </v-col>
          <v-col v-if="provider === 'internal' || provider === null" cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              CONFIRM PASSWORD <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-text-field
              v-model="confirmPassword"
              :rules="confirmPasswordRules"
              placeholder="Enter the password again"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">STATUS</div>
            <v-switch
              v-model="isActive"
              class="mt-0"
              :label="isActive ? 'Active' : 'Inactive'"
              inset
              dense
            />
          </v-col>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import UserService from '@/services/user.service'
import ClientService from '@/services/client.service.js'
import snackbarPlugin from '@/plugins/snackbar'
import BaseDialog from '@/components/dialog/BaseDialog'
import { allRoles, providers } from '@/data/common.js'
import AsyncSearchSelect from '@/components/base/AsyncSearchSelect.vue'

export default {
  components: {
    AsyncSearchSelect,
    BaseDialog
  },
  props: {
    showCreateDialog: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      valid: true,
      allRoles,
      providers,

      itemsClient: [],

      client: null,
      fullName: null,
      email: null,
      phone: null,
      role: null,
      password: null,
      confirmPassword: null,
      provider: null,
      isActive: false
    }
  },
  computed: {
    passwordRules() {
      return [
        password => !!password || 'Password is required',
        password => (password && password.length >= 10) || 'Your password must have at least 10 characters'
      ]
    },
    confirmPasswordRules() {
      return [
        v => !!v || 'Confirm Password is required',
        (this.password === this.confirmPassword) || 'Password must match'
      ]
    },
    emailRules() {
      return [
        email => {
          if (!email) {
            return 'Email is required'
          }
          if (!/^[^@]+@[^@]+$/.test(email)) {
            return 'Invalid email address'
          }
          const domain = email.split('@')[1].split(".")[0]
          if (domain && ['edu', 'gmail', 'hotmail'].indexOf(domain) >= 0) {
            return 'Invalid email domain'
          }
          return true
        }
      ]
    },
    clientId() {
      if (this.client) {
        return this.client.id
      }
      return null
    }
  },
  methods: {
    updateClientId() {
      if (this.role === 'Superuser') {
        this.client = null
      }
    },
    async fetchClientRefs(search) {
      ClientService.getClientRefs(1, 10, search).then((resp) => {
        this.itemsClient = resp.results
      })
    },
    close() {
      this.client = null
      this.fullName = null
      this.email = null
      this.phone = null
      this.role = null
      this.password = null
      this.confirmPassword = null
      this.provider = null
      this.isActive = false
      this.$emit('update:show-create-dialog', false)
    },
    save() {
      if (this.$refs.form.validate()) {
        // delete unused fields (ex: passwords when using external providers)
        if (this.provider !== "internal") {
          this.phone = null
          this.password = null
        }
        snackbarPlugin.showLoading('Creating ...')
        UserService.postUser({
          clientId: this.clientId,
          fullName: this.fullName,
          email: this.email,
          phone: this.phone,
          role: this.role,
          provider: this.provider,
          password: this.password,
          isActive: this.isActive
        }).then(() => {
          this.$emit('reload-items')
          snackbarPlugin.showSuccess(`User ${this.fullName} was created!`)
        })
        this.close()
      } else {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>

<template>
  <div class="px-1">
    <v-row class="mt-0 pt-0">
      <v-col cols="12" class="mt-0 pt-0">
        <user-toolbar
          :filter-keyword.sync="filterSearch"
          :filter-role.sync="filterRole"
          :filter-client.sync="filterClient"
          v-on:reload-items="refreshUsers"
        />
        <user-table
          ref="userTable"
          :filter-search.sync="filterSearchUpdate"
          :filters.sync="userFilters"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import UserService from '@/services/user.service'
import UserTable from '@/components/table/UserTable'
import UserToolbar from '@/components/toolbar/UserToolbar'

export default {
  name: 'Users',
  metaInfo: {
    title: 'Users'
  },
  components: {
    UserTable,
    UserToolbar
  },
  data: () => ({
    filterSearch: null,
    filterRole: null,
    filterClient: null,
    isSuperUser: false
  }),
  computed: {
    userFilters() {
      return {
        role: this.filterRole,
        clientID: this.filterClient ? this.filterClient.id : null
      }
    },
    filterSearchUpdate() {
      if (this.filterSearch !== null) {
        this.$refs.userTable.searchAfterWait()
        return this.filterSearch
      }
    }
  },
  created() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
    }
    this.$store.dispatch('changeThemeLoadingState', false)
  },
  methods: {
    refreshUsers() {
      this.$refs.userTable.fetchUsers()
    }
  }
}
</script>

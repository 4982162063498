<template>
  <div>
    <v-card flat>
      <edit-user-dialog
        :edited-item="selectedUser"
        :show-edit-dialog.sync="showEditDialog"
        v-on:reload-items="$emit('reload-items')"
      />
      <delete-user-dialog
        :edited-item="selectedUser"
        :show-delete-dialog.sync="showDeleteDialog"
        v-on:reload-items="$emit('reload-items')"
      />
      <v-data-table
        :headers="headers"
        :items="users"
        :server-items-length="userCount"
        :loading="isLoading"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: pageSizeOptions }"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar color="grey" class="my-5 d-none d-sm-flex">
            <v-icon medium class="my-2">
              mdi-account-box-outline
            </v-icon>
          </v-avatar>
        </template>
        <template v-slot:item.fullName="{ item }">
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold"
            >{{ item.fullName }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <template v-slot:item.client="{ item }">
          <div
            v-if="item.client"
            class=""
          >
            {{ item.client.name }}
          </div>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <div>
            {{ new Date (item.createdAt.slice(0, 16) + '+0000').toLocaleDateString('fr-CA') }}
          </div>
        </template>

        <template v-slot:item.role="{ item }">
          <v-chip
            v-if="item.role === 'Client User'"
            class="ma-2"
            color="blue"
            outlined
          >
            {{ allRoles[0].text }}
          </v-chip>

          <v-chip
            v-if="item.role === 'Client Admin'"
            class="ma-2"
            color="warning"
            outlined
          >
            {{ allRoles[1].text }}
          </v-chip>
          <v-chip
            v-if="item.role === 'Superuser'"
            class="ma-2"
            color="error darken-2"
            outlined
          >
            {{ allRoles[2].text }}
          </v-chip>
        </template>
        <template v-slot:item.isActive="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.isActive && !item.isDeleted"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="success">
                  mdi-account-check
                </v-icon>
              </v-avatar>
            </template>
            <span>Active</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="!item.isActive && !item.isDeleted"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="warning">
                  mdi-account-off-outline
                </v-icon>
              </v-avatar>
            </template>
            <span>Inactive</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-if="item.isDeleted"
                color="grey"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">
                  mdi-account-remove-outline
                </v-icon>
              </v-avatar>
            </template>
            <span>Deleted</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <span class="d-none d-lg-flex">
            <v-spacer />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary lighten-2"
                  class=""
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.isDeleted || item.role === 'Superuser'"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="danger"
                  class=""
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.isDeleted"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
            <v-spacer />
          </span>
          <v-menu
            bottom
            left
            class="d-md-flex d-lg-none"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="d-md-flex d-lg-none ml-6"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item
                link
                :class="item.isDeleted ? 'grey--text' : 'primary--text text--lighten-2'"
                :disabled="item.isDeleted"
                @click="editItem(item)"
              >
                <v-list-item-icon>
                  <v-icon :class="item.isDeleted ? 'grey--text' : 'primary--text text--lighten-2'">mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                link
                :class="item.isDeleted ? 'grey--text' : 'error--text'"
                :disabled="item.isDeleted"
                @click="deleteItem(item)"
              >
                <v-list-item-icon>
                  <v-icon :class="item.isDeleted ? 'grey--text' : 'error--text'">mdi-delete-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DeleteUserDialog from '@/components/dialog/user/DeleteUserDialog'
import EditUserDialog from '@/components/dialog/user/EditUserDialog'
import { allRoles } from '@/data/common'
import UserService from '@/services/user.service'
import _ from 'lodash'

export default {
  name: 'UserTable',
  components: {
    DeleteUserDialog,
    EditUserDialog
  },
  props: {
    filterSearch: {
      type: String || undefined,
      default: ''
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      selectedUser: {},
      showDeleteDialog: false,
      showEditDialog: false,
      isSuperUser: false,
      allRoles,
      search: this.$route.query.search || '',
      users: [],
      userCount: 0,
      pageSizeOptions: [5, 10, 25],
      isLoading: false,
      debounceSearch: null,
      options: {
        page: parseInt(this.$route.query.page || '1', 10) || 1,
        itemsPerPage: parseInt(this.$route.query.page_size || '5', 10) || 5,
        search: this.$route.query.search || '',
        role: null,
        clientID: null
      },
      previousCall: {
        page: parseInt(this.$route.query.page || '1', 10) || 1,
        itemsPerPage: parseInt(this.$route.query.page_size || '5', 10) || 5,
        search: this.$route.query.search || '',
        role: null,
        clientID: null
      },
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'icon',
          width: '20px',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'User',
          value: 'fullName',
          class: 'text-left font-weight-medium subtitle-1'
        },
        {
          text: '',
          value: '',
          class: ''
        },

        {
          text: 'Role',
          value: 'role',
          align: 'center',
          width: '160px',
          sortable: false,
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Status',
          value: 'isActive',
          align: 'center',
          class: 'text-center font-weight-medium subtitle-1'
        },
        {
          text: 'Created on',
          value: 'createdAt',
          width: '160px',
          align: 'left',
          class: 'text-left font-weight-medium subtitle-1'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '120px',

          align: 'center',
          class: 'text-center font-weight-medium subtitle-1'
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchUsersHandler()
      },
      deep: true
    },
    filters: {
      handler() {
        this.options.role = this.filters.role
        this.options.clientID = this.filters.clientID
      },
      deep: true
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
      if (this.isSuperUser) {
        this.headers[2].text = 'Client'
        this.headers[2].value = 'client'
        this.headers[2].class = 'text-left font-weight-medium subtitle-1'
      }
    }
    this.fetchUsers()
  },
  methods: {
    searchAfterWait() {
      this.search = this.filterSearch
      if (!this.debounceSearch) {
        this.debounceSearch = _.debounce(this.fetchUsersHandler, 700)
      }
      this.debounceSearch()
    },
    fetchUsersHandler() {
      const newCall = {
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.search,
        role: this.options.role,
        clientID: this.options.clientID
      }
      if (
        newCall.page === this.previousCall.page
        && newCall.itemsPerPage === this.previousCall.itemsPerPage
        && newCall.search === this.previousCall.search
        && newCall.role === this.previousCall.role
        && newCall.clientID === this.previousCall.clientID
      ) {
        return
      }
      if (
        (
          newCall.itemsPerPage !== this.previousCall.itemsPerPage
          || newCall.search !== this.previousCall.search
          || newCall.role !== this.previousCall.role
          || newCall.clientID !== this.previousCall.clientID
        ) && this.options.page !== 1
      ) {
        this.options.page = 1 // reset to page 1 will retrigger the call
        return
      }
      this.previousCall = newCall
      this.fetchUsers()
    },
    fetchUsers() {
      this.isLoading = true
      const { page, itemsPerPage, role, clientID } = this.options
      UserService.getUsers(
        page, itemsPerPage, this.search, { role: role, clientID: clientID }
      ).then((users) => {
        this.users = users.results
        this.userCount = users.total
      }).catch(() => {})
      this.isLoading = false
    },
    editItem(item) {
      this.selectedUser = Object.assign({}, item)
      this.showEditDialog = true
    },
    deleteItem(item) {
      this.selectedUser = Object.assign({}, item)
      this.showDeleteDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
table.v-table tbody td,
table.v-table tbody th {
  min-height: 50px !important
}
</style>

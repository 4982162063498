
<template>
  <div>
    <base-dialog
      :show="showInviteDialog"
      icon="mdi-account-plus-outline"
      form-title="Invite User"
      v-on:close="close"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col v-if="isSuperUser" cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">CLIENT <span class="h6 primary--text text--lighten-2">*</span></div>
            <async-search-select
              v-model="client"
              placeholder="Select a client"
              :items="itemsClient"
              item-text="name"
              :items-update="fetchClientRefs"
              :rules="[v => !!v || 'This field is required']"
              base-style=""
              :style-extra="{ dense: true, flat: true, outlined: true, 'hide-details': false}"
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">NAME <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-text-field
              v-model="editedItem.fullName"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter name"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">EMAIL <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-text-field
              v-model="editedItem.email"
              :rules="emailRules"
              placeholder="Enter email"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">PROVIDER <small class="font-italic font-weight-thin">(Optional)</small></div>
            <v-select
              v-model="editedItem.provider"
              :items="providersOrUnspecified"
              placeholder="Select a provider"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">ROLE <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-select
              v-model="editedItem.role"
              placeholder="Select a user role"
              :items="userRoles"
              :rules="[v => !!v || 'This field is required']"
              outlined
              dense
            />
          </v-col>
        </v-form>
      </template>
      <template v-slot:actions>
        <div class="text-right mr-5 mt-n6">
          <v-btn color="primary lighten-2" outlined @click="close" class="mr-3">Cancel</v-btn>
          <v-btn color="primary" depressed @click="invite">
            Invite
          </v-btn>
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import UserService from '@/services/user.service'
import ClientService from '@/services/client.service.js'
import snackbarPlugin from '@/plugins/snackbar'
import BaseDialog from '@/components/dialog/BaseDialog'
import { providersOrUnspecified, userRoles } from '@/data/common.js'
import AsyncSearchSelect from '@/components/base/AsyncSearchSelect.vue'

export default {
  components: {
    AsyncSearchSelect,
    BaseDialog
  },
  props: {
    showInviteDialog: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      isSuperUser: false,
      editedItem: {},
      itemsClient: [],
      client: null,
      providersOrUnspecified,
      userRoles,
      valid: true
    }
  },
  computed: {
    emailRules() {
      return [
        email => {
          if (!email) {
            return 'Email is required'
          }
          if (!/^[^@]+@[^@]+$/.test(email)) {
            return 'Invalid email address'
          }
          const domain = email.split('@')[1].split(".")[0]
          if (domain && ['edu', 'gmail', 'hotmail'].indexOf(domain) >= 0) {
            return 'Invalid email domain'
          }
          return true
        }
      ]
    }
  },
  mounted() {
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
    }
  },
  watch: {
    client: {
      handler() {
        if (this.client != null) {
          this.editedItem.clientId = this.client.id
        }
      }
    }
  },
  methods: {
    async fetchClientRefs(search) {
      ClientService.getClientRefs(1, 10, search).then((resp) => {
        this.itemsClient = resp.results
      })
    },
    close() {
      this.client = null
      this.$emit('update:show-invite-dialog', false)
    },
    async invite() {
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading('Creating ...')
        await UserService.inviteUser(this.editedItem).then(() => {
          snackbarPlugin.showSuccess(`Email sent to ${this.editedItem.email}!`)
          this.$emit('reload-items')
        })
        this.$emit('update:show-invite-dialog', false)

        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>

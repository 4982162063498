<template>
  <div>
    <base-dialog :show="showEditDialog" icon="mdi-pencil" :form-title="formTitle" v-on:close="close" v-on:save="save">
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">NAME <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-text-field
              v-model="editedItem.fullName"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter name"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">EMAIL <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-text-field
              v-model="editedItem.email"
              :rules="emailRules"
              placeholder="Enter email"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">PROVIDER <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-select
              v-model="editedItem.provider"
              :items="providersOrUnspecified"
              outlined
              dense
              @change="isExProvider"
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">USER ROLE <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-select
              v-model="editedItem.role"
              :items="userRoles"
              outlined
              dense
            />
          </v-col>
          <v-col v-if="editedItem.provider === 'internal'" cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">PHONE <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-text-field
              v-model="editedItem.phone"
              placeholder="Enter phone number"
              outlined
              dense
            />
          </v-col>
          <v-col v-if="editedItem.provider === 'internal'" cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">NEW PASSWORD</div>
            <v-text-field
              v-model="editedItem.password"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">STATUS</div>
            <v-switch
              v-model="editedItem.isActive"
              class="mt-0 ml-1"
              :label="editedItem.isActive ? 'Active' : 'Inactive'"
              inset
            />
          </v-col>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import UserService from '@/services/user.service'
import snackbarPlugin from '@/plugins/snackbar'
import { userRoles, providersOrUnspecified } from '@/data/common'

export default {
  components: {
    BaseDialog
  },
  props: {
    showEditDialog: {
      type: Boolean,
      required: true
    },
    editedItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userRoles,
      providersOrUnspecified,
      valid: true
    }
  },
  computed: {
    formTitle() {
      return 'Edit User'
    },
    emailRules() {
      return [
        email => {
          if (!email) {
            return 'Email is required'
          }
          if (!/^[^@]+@[^@]+$/.test(email)) {
            return 'Invalid email address'
          }
          const domain = email.split('@')[1].split(".")[0]
          if (domain && ['edu', 'gmail', 'hotmail'].indexOf(domain) >= 0) {
            return 'Invalid email domain'
          }
          return true
        }
      ]
    }
  },
  methods: {
    isExProvider() {
      if (this.editedItem.providers !== 'internal') {
        this.editedItem.phone = ''
      }
    },
    close() {
      this.$emit('update:show-edit-dialog', false)
    },
    save() {
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading('Updating ...')
        UserService.updateUser(this.editedItem.id, this.editedItem).then((user) => {
          snackbarPlugin.showSuccess(`User (${this.editedItem.email}) updated!`)
          this.$emit('reload-items')
        }).catch(() => {
          // console.log(error)
        })
        this.close()
      }
      if (!this.$refs.form.validate()) {
        snackbarPlugin.showError('Complete the missing required fields!')
      }
    }
  }
}
</script>
